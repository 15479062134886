import React, { useState } from "react";
import { FaPlane, FaUser, FaMapMarkerAlt, FaFileExport, FaSearch, FaSort, FaTrash, FaEdit, FaPlus, FaMinus, FaTasks, FaUserPlus, FaChartBar, FaDownload, FaRobot } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

const DronePilotSystem = () => {
  const [activeTab, setActiveTab] = useState("form");
  const [missionDate, setMissionDate] = useState(new Date());
  const [showExportModal, setShowExportModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState({ start: new Date(), end: new Date() });
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [isEditing, setIsEditing] = useState(false);
  const [editingMissionId, setEditingMissionId] = useState(null);
  const [formData, setFormData] = useState({
    pilotName: "",
    safetyPilot: "",
    startTime: new Date(),
    endTime: new Date(),
    location: "",
    status: "Not Started",
    areaSize: "",
    areaCompleted: "0",
    droneType: "",
    pesticide: "",
    caaStatus: "",
    notamStatus: "",
    towns: [{ name: "", progress: 0 }]
  });

  const locationOptions = [
    { value: "all", label: "All Locations" },
    { value: "adDakhiliyah", label: "Ad Dakhiliyah" },
    { value: "adDhahirah", label: "Ad Dhahirah" },
    { value: "alBatinahNorth", label: "Al Batinah North" },
    { value: "alBatinahSouth", label: "Al Batinah South" },
    { value: "alBuraimi", label: "Al Buraimi" },
    { value: "alWusta", label: "Al Wusta" },
    { value: "ashSharqiyahNorth", label: "Ash Sharqiyah North" },
    { value: "ashSharqiyahSouth", label: "Ash Sharqiyah South" },
    { value: "dhofar", label: "Dhofar" },
    { value: "muscat", label: "Muscat" },
    { value: "musandam", label: "Musandam" }
  ];

  const droneOptions = [
    { value: "t40", label: "T40" },
    { value: "t50", label: "T50" }
  ];

  const caaStatusOptions = [
    { value: "valid", label: "Valid" },
    { value: "expired", label: "Expired" }
  ];

  const notamStatusOptions = [
    { value: "published", label: "Published" },
    { value: "expired", label: "Expired" }
  ];

  const [missions, setMissions] = useState([
    {
      id: 1,
      date: "2024-01-15",
      pilot: "John Doe",
      safetyPilot: "Mike Johnson",
      location: "Ad Dakhiliyah",
      progress: 75,
      status: "In Progress",
      areaSize: "1000",
      areaCompleted: "750",
      areaCompletedPercentage: 75,
      startTime: "09:00 AM",
      endTime: "05:00 PM",
      droneType: "T40",
      pesticide: "50",
      caaStatus: "Valid",
      notamStatus: "Published"
    },
    {
      id: 2,
      date: "2024-01-14",
      pilot: "Jane Smith",
      safetyPilot: "Sarah Wilson",
      location: "Muscat",
      progress: 100,
      status: "Completed",
      areaSize: "800",
      areaCompleted: "800",
      areaCompletedPercentage: 100,
      startTime: "08:00 AM",
      endTime: "04:00 PM",
      droneType: "T50",
      pesticide: "75",
      caaStatus: "Expired",
      notamStatus: "Expired"
    }
  ]);

  const handleEdit = (mission) => {
    setIsEditing(true);
    setEditingMissionId(mission.id);
    setMissionDate(new Date(mission.date));
    setStartTime(new Date(`2024-01-01 ${mission.startTime}`));
    setEndTime(new Date(`2024-01-01 ${mission.endTime}`));
    setFormData({
      pilotName: mission.pilot,
      safetyPilot: mission.safetyPilot,
      startTime: new Date(`2024-01-01 ${mission.startTime}`),
      endTime: new Date(`2024-01-01 ${mission.endTime}`),
      location: mission.location,
      status: mission.status,
      areaSize: mission.areaSize,
      areaCompleted: mission.areaCompleted,
      droneType: mission.droneType,
      pesticide: mission.pesticide,
      caaStatus: mission.caaStatus,
      notamStatus: mission.notamStatus
    });
    setActiveTab("form");
  };

  const filteredMissions = selectedLocation && selectedLocation.value !== "all"
    ? missions.filter(mission => mission.location === selectedLocation.label)
    : missions;

  const totalAreaCompleted = filteredMissions.reduce((total, mission) => {
    return total + parseFloat(mission.areaCompleted);
  }, 0);

  const totalAreaSize = filteredMissions.reduce((total, mission) => {
    return total + parseFloat(mission.areaSize);
  }, 0);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const areaCompletedPercentage = (parseFloat(formData.areaCompleted) / parseFloat(formData.areaSize)) * 100;
    
    if (isEditing) {
      const updatedMissions = missions.map(mission => {
        if (mission.id === editingMissionId) {
          return {
            ...mission,
            date: missionDate.toISOString().split("T")[0],
            pilot: formData.pilotName,
            safetyPilot: formData.safetyPilot,
            location: formData.location,
            status: formData.status,
            areaSize: formData.areaSize,
            areaCompleted: formData.areaCompleted,
            areaCompletedPercentage: areaCompletedPercentage,
            startTime: startTime.toLocaleTimeString(),
            endTime: endTime.toLocaleTimeString(),
            droneType: formData.droneType,
            pesticide: formData.pesticide,
            caaStatus: formData.caaStatus,
            notamStatus: formData.notamStatus
          };
        }
        return mission;
      });
      setMissions(updatedMissions);
      setIsEditing(false);
      setEditingMissionId(null);
    } else {
      const newMission = {
        id: missions.length + 1,
        date: missionDate.toISOString().split("T")[0],
        pilot: formData.pilotName,
        safetyPilot: formData.safetyPilot,
        location: formData.location,
        progress: 0,
        status: formData.status,
        areaSize: formData.areaSize,
        areaCompleted: formData.areaCompleted,
        areaCompletedPercentage: areaCompletedPercentage,
        startTime: startTime.toLocaleTimeString(),
        endTime: endTime.toLocaleTimeString(),
        droneType: formData.droneType,
        pesticide: formData.pesticide,
        caaStatus: formData.caaStatus,
        notamStatus: formData.notamStatus
      };
      setMissions([...missions, newMission]);
    }

    setFormData({
      pilotName: "",
      safetyPilot: "",
      startTime: new Date(),
      endTime: new Date(),
      location: "",
      status: "Not Started",
      areaSize: "",
      areaCompleted: "0",
      droneType: "",
      pesticide: "",
      caaStatus: "",
      notamStatus: "",
      towns: [{ name: "", progress: 0 }]
    });
    setActiveTab("dashboard");
  };

  const MissionForm = () => (
    <form onSubmit={handleFormSubmit} className="space-y-6 p-8 bg-white rounded-xl shadow-2xl max-w-2xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-indigo-600">
          {isEditing ? "Update Mission" : "New Mission"}
        </h2>
      </div>
      <div className="space-y-6">
        <div className="flex flex-col">
          <label className="text-sm font-semibold text-gray-700 mb-1">Mission Date</label>
          <DatePicker
            selected={missionDate}
            onChange={(date) => setMissionDate(date)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300"
          />
        </div>
  
        <div className="flex flex-col">
          <label className="text-sm font-semibold text-gray-700 mb-1">Pilot Name</label>
          <input
            type="text"
            value={formData.pilotName}
            onChange={(e) => setFormData({ ...formData, pilotName: e.target.value })}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
          />
        </div>
  
        <div className="flex flex-col">
          <label className="text-sm font-semibold text-gray-700 mb-1">Safety Pilot</label>
          <input
            type="text"
            value={formData.safetyPilot}
            onChange={(e) => setFormData({ ...formData, safetyPilot: e.target.value })}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
          />
        </div>
  
        <div className="flex space-x-4">
          <div className="flex flex-col flex-1">
            <label className="text-sm font-semibold text-gray-700 mb-1">Start Time</label>
            <DatePicker
              selected={startTime}
              onChange={(time) => setStartTime(time)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            />
          </div>
  
          <div className="flex flex-col flex-1">
            <label className="text-sm font-semibold text-gray-700 mb-1">End Time</label>
            <DatePicker
              selected={endTime}
              onChange={(time) => setEndTime(time)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            />
          </div>
        </div>
  
        <div className="flex flex-col">
          <label className="text-sm font-semibold text-gray-700 mb-1">Location</label>
          <Select
            options={locationOptions}
            value={locationOptions.find(option => option.label === formData.location)}
            onChange={(option) => setFormData({ ...formData, location: option.label })}
            className="basic-select"
          />
        </div>
  
        <div className="flex space-x-4">
          <div className="flex flex-col flex-1">
            <label className="text-sm font-semibold text-gray-700 mb-1">Area Size (acres)</label>
            <input
              type="number"
              value={formData.areaSize}
              onChange={(e) => setFormData({ ...formData, areaSize: e.target.value })}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            />
          </div>
  
          <div className="flex flex-col flex-1">
            <label className="text-sm font-semibold text-gray-700 mb-1">Area Completed (acres)</label>
            <input
              type="number"
              value={formData.areaCompleted}
              onChange={(e) => setFormData({ ...formData, areaCompleted: e.target.value })}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            />
          </div>
        </div>
  
        <div className="flex flex-col">
          <label className="text-sm font-semibold text-gray-700 mb-1">Drone Type</label>
          <Select
            options={droneOptions}
            value={droneOptions.find(option => option.value === formData.droneType.toLowerCase())}
            onChange={(option) => setFormData({ ...formData, droneType: option.label })}
            className="basic-select"
          />
        </div>
  
        <div className="flex flex-col">
          <label className="text-sm font-semibold text-gray-700 mb-1">Pesticide Amount (liters)</label>
          <input
            type="number"
            value={formData.pesticide}
            onChange={(e) => setFormData({ ...formData, pesticide: e.target.value })}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
          />
        </div>
  
        <div className="flex space-x-4">
          <div className="flex flex-col flex-1">
            <label className="text-sm font-semibold text-gray-700 mb-1">CAA Status</label>
            <Select
              options={caaStatusOptions}
              value={caaStatusOptions.find(option => option.value === formData.caaStatus.toLowerCase())}
              onChange={(option) => setFormData({ ...formData, caaStatus: option.label })}
              className="basic-select"
            />
          </div>
  
          <div className="flex flex-col flex-1">
            <label className="text-sm font-semibold text-gray-700 mb-1">NOTAM Status</label>
            <Select
              options={notamStatusOptions}
              value={notamStatusOptions.find(option => option.value === formData.notamStatus.toLowerCase())}
              onChange={(option) => setFormData({ ...formData, notamStatus: option.label })}
              className="basic-select"
            />
          </div>
        </div>
  
        <div className="space-y-4">
          <label className="text-sm font-semibold text-gray-700 block mb-2">Approvals</label>
          <div className="flex flex-col space-y-3">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={formData.rop}
                onChange={(e) => setFormData({ ...formData, rop: e.target.checked })}
                className="form-checkbox h-5 w-5 text-indigo-600 rounded border-gray-300 focus:ring-indigo-500"
              />
              <span className="ml-2 text-gray-700">ROP (Royal Oman Police)</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={formData.rafo}
                onChange={(e) => setFormData({ ...formData, rafo: e.target.checked })}
                className="form-checkbox h-5 w-5 text-indigo-600 rounded border-gray-300 focus:ring-indigo-500"
              />
              <span className="ml-2 text-gray-700">RAFO (Royal Air Force of Oman)</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={formData.mafwr}
                onChange={(e) => setFormData({ ...formData, mafwr: e.target.checked })}
                className="form-checkbox h-5 w-5 text-indigo-600 rounded border-gray-300 focus:ring-indigo-500"
              />
              <span className="ml-2 text-gray-700">MAFWR (Ministry of Agriculture)</span>
            </label>
          </div>
        </div>
      </div>
      <button
        type="submit"
        className="w-full bg-gradient-to-r from-indigo-600 to-indigo-700 text-white py-3 px-6 rounded-lg hover:from-indigo-700 hover:to-indigo-800 transition-all duration-300 transform hover:scale-[1.02] active:scale-[0.98] font-medium text-sm flex items-center justify-center space-x-2"
      >
        {isEditing ? (
          <>
            <FaEdit className="w-4 h-4" />
            <span>Update Mission</span>
          </>
        ) : (
          <>
            <FaPlus className="w-4 h-4" />
            <span>Submit Mission</span>
          </>
        )}
      </button>
    </form>
  );

  const Dashboard = () => {
    const groupedMissions = filteredMissions.reduce((acc, mission) => {
      if (!acc[mission.location]) {
        acc[mission.location] = [];
      }
      acc[mission.location].push(mission);
      return acc;
    }, {});

    return (
      <div className="space-y-8 p-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <div className="bg-white p-6 rounded-xl shadow-lg">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">Total Area</h3>
            <p className="text-3xl font-bold text-indigo-600">{totalAreaSize} acres</p>
          </div>
          <div className="bg-white p-6 rounded-xl shadow-lg">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">Area Completed</h3>
            <p className="text-3xl font-bold text-green-600">{totalAreaCompleted} acres</p>
          </div>
          <div className="bg-white p-6 rounded-xl shadow-lg">
            <h3 className="text-lg font-semibold text-gray-800 mb-4">Completion Rate</h3>
            <p className="text-3xl font-bold text-blue-600">
              {totalAreaSize > 0 ? ((totalAreaCompleted / totalAreaSize) * 100).toFixed(1) : 0}%
            </p>
          </div>
        </div>

        <div className="flex space-x-4 mb-6">
          <Select
            options={locationOptions}
            value={selectedLocation}
            onChange={setSelectedLocation}
            placeholder="Filter by location"
            className="w-48"
          />
        </div>

        <div className="grid grid-cols-1 gap-8">
          {Object.entries(groupedMissions).map(([location, locationMissions]) => (
            <div key={location} className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
              <h3 className="text-xl font-bold text-gray-800 mb-6 flex items-center">
                <FaMapMarkerAlt className="text-indigo-600 mr-3" />
                {location}
              </h3>
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                {locationMissions.map((mission) => (
                  <div key={mission.id} className="bg-gradient-to-br from-indigo-50 to-white p-6 rounded-xl border border-indigo-100">
                    <div className="flex items-center justify-between mb-4">
                      <div className="flex items-center">
                        <div className="p-2 bg-indigo-600 rounded-lg">
                          <FaUser className="text-white" />
                        </div>
                        <span className="ml-3 font-semibold text-gray-800">{mission.pilot}</span>
                      </div>
                      <div className="flex space-x-2">
                        <button
                          onClick={() => handleEdit(mission)}
                          className="p-2 text-indigo-600 hover:bg-indigo-100 rounded-full transition-colors duration-300"
                        >
                          <FaEdit className="w-5 h-5" />
                        </button>
                      </div>
                    </div>

                    <div className="space-y-3">
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-600">Date:</span>
                        <span className="font-medium text-gray-800">{mission.date}</span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-600">Time:</span>
                        <span className="font-medium text-gray-800">{mission.startTime} - {mission.endTime}</span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-600">Status:</span>
                        <span className={`font-medium ${mission.status === "Completed" ? "text-green-600" : "text-blue-600"}`}>
                          {mission.status}
                        </span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-600">CAA Status:</span>
                        <span className={`font-medium ${mission.caaStatus === "Valid" ? "text-green-600" : "text-red-600"}`}>
                          {mission.caaStatus}
                        </span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-600">NOTAM Status:</span>
                        <span className={`font-medium ${mission.notamStatus === "Published" ? "text-green-600" : "text-red-600"}`}>
                          {mission.notamStatus}
                        </span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-600">Area Completed:</span>
                        <span className="font-medium text-gray-800">{mission.areaCompleted}/{mission.areaSize} acres</span>
                      </div>
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-600">Drone:</span>
                        <span className="font-medium text-gray-800">{mission.droneType}</span>
                      </div>
                    </div>

                    <div className="mt-4">
                      <div className="relative pt-1">
                        <div className="flex mb-2 items-center justify-between">
                          <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-indigo-600 bg-indigo-200">
                              Progress
                            </span>
                          </div>
                          <div className="text-right">
                            <span className="text-xs font-semibold inline-block text-indigo-600">
                              {mission.areaCompletedPercentage}%
                            </span>
                          </div>
                        </div>
                        <div className="flex h-2 mb-4 overflow-hidden rounded bg-indigo-200">
                          <div
                            style={{ width: `${mission.areaCompletedPercentage}%` }}
                            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-indigo-500"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const ExportModal = () => (
    <div className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center transition-opacity duration-300 ${showExportModal ? "opacity-100" : "opacity-0 pointer-events-none"}`}>
      <div className="bg-white p-8 rounded-xl shadow-2xl w-96 transform transition-transform duration-300 scale-100">
        <h3 className="text-xl font-semibold text-gray-800 mb-6">Export Data</h3>
        <div className="space-y-4">
          <button className="w-full bg-gradient-to-r from-indigo-600 to-indigo-700 text-white py-3 px-6 rounded-lg hover:from-indigo-700 hover:to-indigo-800 transition-all duration-300 transform hover:scale-[1.02] active:scale-[0.98] flex items-center justify-center">
            <FaFileExport className="mr-2" /> Export as CSV
          </button>
          <button className="w-full bg-gradient-to-r from-indigo-600 to-indigo-700 text-white py-3 px-6 rounded-lg hover:from-indigo-700 hover:to-indigo-800 transition-all duration-300 transform hover:scale-[1.02] active:scale-[0.98] flex items-center justify-center">
            <FaFileExport className="mr-2" /> Export as PDF
          </button>
        </div>
        <button
          onClick={() => setShowExportModal(false)}
          className="mt-6 w-full border border-gray-300 text-gray-600 py-3 px-6 rounded-lg hover:bg-gray-50 transition-all duration-300 transform hover:scale-[1.02] active:scale-[0.98] flex items-center justify-center"
        >
          Cancel
        </button>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-100">
      <nav className="bg-gradient-to-r from-indigo-600 to-indigo-800 text-white p-6 shadow-xl">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-2xl font-bold flex items-center">
            <FaRobot className="mr-3 text-3xl" /> Drone Pilot System
          </h1>
          <div className="flex space-x-4">
            <button
              onClick={() => setShowExportModal(true)}
              className="flex items-center bg-white text-indigo-600 px-6 py-2 rounded-lg hover:bg-indigo-50 transition-all duration-300 transform hover:scale-[1.02] active:scale-[0.98] shadow-md"
            >
              <FaFileExport className="mr-2" /> Export
            </button>
          </div>
        </div>
      </nav>

      <div className="container mx-auto py-8 px-6">
        <div className="flex space-x-4 mb-8">
          {[
            { id: "form", label: isEditing ? "Update Mission" : "New Mission", icon: isEditing ? FaEdit : FaPlus },
            { id: "dashboard", label: "Dashboard", icon: FaChartBar }
          ].map(({ id, label, icon: Icon }) => (
            <button
              key={id}
              onClick={() => setActiveTab(id)}
              className={`flex items-center px-6 py-3 rounded-lg transition-all duration-300 transform hover:scale-[1.02] active:scale-[0.98] ${activeTab === id ? "bg-indigo-600 text-white shadow-lg" : "bg-white text-gray-600 hover:bg-gray-50"}`}
            >
              <Icon className="mr-2" /> {label}
            </button>
          ))}
        </div>

        {activeTab === "form" && <MissionForm />}
        {activeTab === "dashboard" && <Dashboard />}
        <ExportModal />
      </div>
    </div>
  );
};

export default DronePilotSystem;
